import type { JSONinterface } from './types'
import React from 'react'
import InputMask from 'react-input-mask'

export default function (_JSON: JSONinterface) {
  return recurse(_JSON)
}

function recurse(JSON_: JSONinterface) {
  switch (JSON_.component) {
    case 'button':
      return (
        <button
          className={JSON_.class}
          key={JSON_.key}
          onClick={JSON_.onClick}
          style={JSON_.style ? JSON_.style : {}}
        >
          {JSON_.children?.length
            ? JSON_.children.map((child) => recurse(child))
            : JSON_.text}
        </button>
      )
      break
    case 'container':
      return (
        <div
          key={JSON_.key}
          className={JSON_.class}
          onClick={JSON_.onClick}
          style={JSON_.style ? JSON_.style : {}}
        >
          {JSON_.children?.length
            ? JSON_.children.map((child) => recurse(child))
            : JSON_.text}
        </div>
      )
      break
    case 'nav':
      return (
        <nav
          className={JSON_.class}
          key={JSON_.key}
          onClick={JSON_.onClick}
          style={JSON_.style ? JSON_.style : {}}
        >
          {JSON_.children?.length
            ? JSON_.children.map((child) => recurse(child))
            : JSON_.text}
        </nav>
      )
      break
    case 'text':
      return (
        <p
          key={JSON_.key}
          className={JSON_.class}
          onClick={JSON_.onClick}
          style={JSON_.style ? JSON_.style : {}}
        >
          {JSON_.children?.length
            ? JSON_.children.map((child) => recurse(child))
            : JSON_.text}
        </p>
      )
      break
    case 'span':
      return (
        <span
          className={JSON_.class}
          key={JSON_.key}
          onClick={JSON_.onClick}
          style={JSON_.style ? JSON_.style : {}}
        >
          {JSON_.children?.length
            ? JSON_.children.map((child) => recurse(child))
            : JSON_.text}
        </span>
      )
      break
    case 'list':
      return (
        <ul
          className={JSON_.class}
          onClick={JSON_.onClick}
          key={JSON_.key}
          style={JSON_.style ? JSON_.style : {}}
        >
          {JSON_.children?.length
            ? JSON_.children.map((child) => recurse(child))
            : JSON_.text}
        </ul>
      )
      break
    case 'list_item':
      return (
        <li
          className={JSON_.class}
          onClick={JSON_.onClick}
          key={JSON_.key}
          style={JSON_.style ? JSON_.style : {}}
        >
          {JSON_.children?.length
            ? JSON_.children.map((child) => recurse(child))
            : JSON_.text}
        </li>
      )
      break
    case 'image':
      return (
        <img
          src={JSON_.src}
          className={JSON_.class}
          onClick={JSON_.onClick}
          key={JSON_.key}
          style={JSON_.style ? JSON_.style : {}}
        />
      )
      break
    case 'input':
      return (
        <input
          className={JSON_.class}
          onChange={JSON_.onChange}
          onBlur={JSON_.onBlur}
          onKeyUp={JSON_.onKeyUp}
          disabled={JSON_.disabled}
          style={JSON_.style ? JSON_.style : {}}
          type={JSON_.type ? JSON_.type : 'text'}
          placeholder={JSON_.placeholder ? JSON_.placeholder : undefined}
          key={JSON_.key}
        />
      )
      break
    case 'input_mask':
      return (
        <InputMask
          mask={JSON_.mask}
          value={JSON_.value}
          onChange={JSON_.onChange}
          key={JSON_.key}
        >
          {() => (
            <input
              value={JSON_.value}
              onChange={JSON_.onChange}
              key={JSON_.key}
            />
          )}
        </InputMask>
      )
      break
    default:
      if (JSON_.render) {
        return JSON_.render
      }
      return console.log(`${JSON_.component} não foi encontrado`)

      break
  }
}
